import { all, call, put, select } from "redux-saga/effects";
import log from "loglevel";

import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { takeLatest } from "redux-saga/effects";
import {
  createMessage,
  FETCH_PRORATED_INVOICE_ERROR,
  SUBSCRIPTIONS_UPDATE_SEATS_ERROR,
  UPCOMING_INVOICE_SUMMARY_FETCH_ERROR,
} from "constants/Messages";
import {
  fetchProratedInvoice,
  fetchProratedInvoiceFailed,
  fetchProratedInvoiceSuccess,
  fetchUpcomingInvoiceSummary,
  fetchUpcomingInvoiceSummarySuccess,
  setUpcomingInvoiceLoading,
  updateSeatsFailed,
  updateSeatsSuccess,
} from "actions/SubscriptionActions";
import { getErrorMessage, isValidResponse, showToaster } from "./utilitySaga";
import { ApiResponse } from "types/ApiTypes";
import {
  ProratedInvoiceResponseType,
  UpcomingInvoiceSummaryResponseType,
} from "types/SubscriptionTypes";
import SuscriptionsApi from "api/SuscriptionsApi";
import { ReduxAction } from "types/ReduxTypes";
import { MEMBERS } from "api/constants";
import { FlowType } from "types/LicenseTypes";
import { getFlowType } from "selectors/LicenseSelectors";
import Analytics from "thirdparty/analytics";
import analyticEvents from "constants/AnalyticEvents";

export function* onFetchUpcomingInvoiceSummary(action?: ReduxAction<number>) {
  const seatCount = action?.payload;

  try {
    yield put(setUpcomingInvoiceLoading(true));

    const response: ApiResponse<UpcomingInvoiceSummaryResponseType> =
      yield call(SuscriptionsApi.fetchUpcomingInvoiceSummary, seatCount);

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      yield put(fetchUpcomingInvoiceSummarySuccess(response.data));

      yield put(setUpcomingInvoiceLoading(false));
    } else {
      throw new Error(createMessage(UPCOMING_INVOICE_SUMMARY_FETCH_ERROR));
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error);
    log.error(errorMessage);
    yield call(showToaster, errorMessage);
    yield put(setUpcomingInvoiceLoading(false));
  }
}

export function* onUpdateSeats(action: ReduxAction<number>) {
  const seatCount = action.payload;

  try {
    const response: ApiResponse<unknown> = yield call(
      SuscriptionsApi.updateSeats,
      seatCount,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      Analytics.track(analyticEvents.UPDATE_SEATS, {
        seatCount,
      });

      yield put(updateSeatsSuccess());

      window.location.href = MEMBERS + "?updateSeats=success";
    } else {
      Analytics.track(analyticEvents.UPDATE_SEATS_FAILED, {
        seatCount,
      });

      yield put(updateSeatsFailed());

      throw new Error(createMessage(SUBSCRIPTIONS_UPDATE_SEATS_ERROR));
    }
  } catch (error: any) {
    Analytics.track(analyticEvents.UPDATE_SEATS_FAILED, {
      seatCount,
    });

    yield put(updateSeatsFailed());

    const errorMessage = getErrorMessage(error);
    log.error(errorMessage);

    yield call(showToaster, errorMessage);
  }
}

export function* onFetchProratedInvoice(action?: ReduxAction<number>) {
  const seatCount = action?.payload;

  try {
    const response: ApiResponse<ProratedInvoiceResponseType> = yield call(
      SuscriptionsApi.fetchProratedInvoice,
      seatCount,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      yield put(fetchProratedInvoiceSuccess(response.data));
    } else {
      throw new Error(createMessage(FETCH_PRORATED_INVOICE_ERROR));
    }
  } catch (error: any) {
    yield put(fetchProratedInvoiceFailed());
    const errorMessage = getErrorMessage(error);
    log.error(errorMessage);
    yield call(showToaster, errorMessage);
  }
}

export function* onSetSubscriptionsSeats(action: ReduxAction<number>) {
  const flowType: FlowType = yield select(getFlowType);
  const seatCount = action.payload;

  if (flowType === FlowType.SEAT_BASED_UPDATE) {
    yield put(fetchProratedInvoice(seatCount));
  } else {
    yield put(fetchUpcomingInvoiceSummary(seatCount));
  }
}

export function* SubscriptionSaga() {
  yield all([
    takeLatest(
      ReduxActionTypes.FETCH_SUBSCRIPTIONS_UPCOMING_INVOICE_SUMMARY,
      onFetchUpcomingInvoiceSummary,
    ),
    takeLatest(
      ReduxActionTypes.SET_SUBSCRIPTIONS_SEATS,
      onSetSubscriptionsSeats,
    ),
    takeLatest(ReduxActionTypes.UPDATE_SUBSCRIPTIONS_SEATS, onUpdateSeats),
    takeLatest(
      ReduxActionTypes.FETCH_SUBSCRIPTIONS_PRORATED_INVOICE,
      onFetchProratedInvoice,
    ),
  ]);
}
