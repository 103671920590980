import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Callout,
  CalloutLinkProps,
  Flex,
  NumberInput,
  Text,
} from "@appsmith/ads";

import {
  CONTACT_US_CTA,
  createMessage,
  PRICING_SUMMARY_PURCHASE_SEATS,
  SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT,
} from "constants/Messages";
import { useGetInitialSeatCount, useShowIntercomWithText } from "hooks";
import { getOccupiedSeatCount } from "selectors/MembersSelectors";
import { debounce } from "lodash";
import { setSeats } from "actions/SubscriptionActions";
import { getLicenseAccountStatus } from "selectors/AccountSelectors";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { getSeatBasedPricingEnvData } from "selectors/EnvironmentSelectors";
import Analytics from "thirdparty/analytics";
import analyticEvents from "constants/AnalyticEvents";

const AddSeat = () => {
  const dispatch = useDispatch();
  const [seatCount, setSeatCount] = useState(0);

  const occupiedSeats = useSelector(getOccupiedSeatCount);

  const accountStatus = useSelector(getLicenseAccountStatus);

  const seatBasedPricingEnvData = useSelector(getSeatBasedPricingEnvData);

  const isEnvDataValid = !(
    seatBasedPricingEnvData.maxSeats === 0 &&
    seatBasedPricingEnvData.minSeats === 0
  );

  const { showIntercomWithText } = useShowIntercomWithText();

  const minSeatCount = useMemo(() => {
    if (
      accountStatus === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE ||
      accountStatus === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
      accountStatus === ACCOUNT_STATUS_TYPES.PAID_EXPIRED
    ) {
      return seatBasedPricingEnvData.minSeats;
    }

    return Math.max(seatBasedPricingEnvData.minSeats, occupiedSeats || 1);
  }, [accountStatus, seatBasedPricingEnvData.minSeats, occupiedSeats]);

  const initialSeatCount = useGetInitialSeatCount();

  useEffect(() => {
    handleSetSeats(initialSeatCount.toString());
  }, [initialSeatCount, seatBasedPricingEnvData]);

  // debounce and call setSeats
  const debouncedSetSeats = useCallback(
    debounce((value: number) => {
      dispatch(setSeats(value));
      Analytics.track(analyticEvents.SEAT_COUNT_CHANGE, {
        seatCount: value,
      });
    }, 300),
    [dispatch],
  );

  const handleSetSeats = useCallback(
    (value: string | undefined) => {
      if (
        Number(value) > seatBasedPricingEnvData.maxSeats ||
        Number(value) < minSeatCount ||
        Number(value) === seatCount
      ) {
        return;
      }
      const _seatCount = Number(value) || minSeatCount;

      setSeatCount(_seatCount);
      debouncedSetSeats(_seatCount);
    },
    [
      debouncedSetSeats,
      minSeatCount,
      seatBasedPricingEnvData.maxSeats,
      seatCount,
    ],
  );

  const calloutLinks: { links?: CalloutLinkProps[] } = {};

  if (seatCount === seatBasedPricingEnvData.maxSeats) {
    calloutLinks.links = [
      {
        children: createMessage(CONTACT_US_CTA),
        onClick: showIntercomWithText,
      },
    ];
  }

  return (
    <Flex flexDirection="column" gap="spaces-5" w="100%">
      <Flex
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        w="100%"
      >
        <Flex flexDirection="column" gap="spaces-2">
          <Text kind="heading-s">
            {createMessage(PRICING_SUMMARY_PURCHASE_SEATS.select_seats)}
          </Text>
          <Text kind="body-m">
            {createMessage(
              SEAT_BASED_BILLING_PRICE_PER_MONTH_TEXT,
              seatBasedPricingEnvData.pricePerSeat,
            )}
          </Text>
        </Flex>
        <Flex>
          <NumberInput
            aria-label="seat-count"
            max={seatBasedPricingEnvData.maxSeats}
            min={minSeatCount}
            onChange={handleSetSeats}
            value={seatCount.toString()}
          />
        </Flex>
      </Flex>
      {(seatCount === seatBasedPricingEnvData.minSeats ||
        seatCount === seatBasedPricingEnvData.maxSeats) &&
      isEnvDataValid ? (
        <Flex w="100%">
          <Callout className="!w-full" kind="warning" {...calloutLinks}>
            {seatCount === seatBasedPricingEnvData.minSeats
              ? createMessage(
                  PRICING_SUMMARY_PURCHASE_SEATS.min_seats_warning,
                  seatBasedPricingEnvData.minSeats,
                )
              : createMessage(
                  PRICING_SUMMARY_PURCHASE_SEATS.seats_full_warning,
                  seatBasedPricingEnvData.maxSeats,
                )}
          </Callout>
        </Flex>
      ) : null}
    </Flex>
  );
};

export { AddSeat };
