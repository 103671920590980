import {
  goToPaymentDashboardFailed,
  initiateCheckoutFailed,
  initiateSeatBasedCheckoutCompleted,
} from "actions/paymentActions";
import PaymentsApi from "api/PaymentsApi";
import analyticEvents from "constants/AnalyticEvents";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { toast } from "@appsmith/ads";
import { all, call, put, takeLatest } from "redux-saga/effects";
import Analytics from "thirdparty/analytics";
import log from "loglevel";
import { isValidResponse } from "./utilitySaga";
import { ApiResponse } from "types/ApiTypes";
import { CheckoutResponse, DashboardRepsonse } from "types/paymentsTypes";
import { createMessage, GENERIC_ERROR_MESSAGE } from "constants/Messages";
import { ReduxAction } from "types/ReduxTypes";

export function* initiateCheckout() {
  yield call(Analytics.track, analyticEvents.CHECKOUT_CLICK_PORTAL);

  try {
    const response: ApiResponse<CheckoutResponse> = yield call(
      PaymentsApi.initiateCheckout,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      window.location.assign(response.data.checkoutUrl);
    } else {
      throw new Error();
    }
  } catch (e) {
    log.error(e);

    yield put(initiateCheckoutFailed());

    toast.show(createMessage(GENERIC_ERROR_MESSAGE), {
      kind: "error",
    });
  }
}

export function* goToPaymentDashboard() {
  try {
    const response: ApiResponse<DashboardRepsonse> = yield call(
      PaymentsApi.goToDashboard,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      window.open(response.data.customerDashboardUrl, "_blank");
    } else {
      throw new Error();
    }
  } catch (e) {
    log.error(e);

    yield put(goToPaymentDashboardFailed());

    toast.show(createMessage(GENERIC_ERROR_MESSAGE), {
      kind: "error",
    });
  }
}

export function* initiateSeatBasedCheckout(action: ReduxAction<number>) {
  const seatCount = action.payload;
  try {
    const response: ApiResponse<CheckoutResponse> = yield call(
      PaymentsApi.initiateSeatBasedCheckout,
      seatCount,
    );

    const isValid: boolean = yield call(isValidResponse, response);
    if (isValid) {
      Analytics.track(analyticEvents.INITIATE_SEAT_BASED_CHECKOUT, {
        seatCount,
      });

      window.location.assign(response.data.checkoutUrl);
    } else {
      Analytics.track(analyticEvents.INITIATE_SEAT_BASED_CHECKOUT_FAILED, {
        seatCount,
      });

      throw new Error();
    }
  } catch (error: any) {
    let errorMessage = error.responseMeta?.error?.message || error.message;
    errorMessage = "Error purchasing seats: " + errorMessage;
    log.error(errorMessage);

    yield put(initiateCheckoutFailed());
  } finally {
    yield put(initiateSeatBasedCheckoutCompleted());
  }
}

export function* PaymentsSaga() {
  yield all([
    takeLatest(ReduxActionTypes.INITIATE_CHECKOUT, initiateCheckout),
    takeLatest(ReduxActionTypes.GO_TO_PAYMENT_DASHBOARD, goToPaymentDashboard),
    takeLatest(
      ReduxActionTypes.INITIATE_SEAT_BASED_CHECKOUT,
      initiateSeatBasedCheckout,
    ),
  ]);
}
