const analyticEvents = {
  USER_LOGIN_INIT: "USER_LOGIN_INIT",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",

  LICENSE_PAGE_CLICK_PORTAL: "LICENSE_PAGE_CLICK_PORTAL",
  PLAN_BUILDER_CLICK_PORTAL: "PLAN_BUILDER_CLICK_PORTAL",
  USAGE_PAGE_CLICK_PORTAL: "USAGE_PAGE_CLICK_PORTAL",
  MEMBERS_PAGE_CLICK_PORTAL: "MEMBERS_PAGE_CLICK_PORTAL",
  VIEW_DOCKER_DOCS: "VIEW_DOCKER_DOCS",
  VIEW_DOCKER_INSTALL: "VIEW_DOCKER_INSTALL",
  VIEW_DOCKER_UPGRADE: "VIEW_DOCKER_UPGRADE",
  VIEW_KUBERNETES_DOCS: "VIEW_KUBERNETES_DOCS",
  VIEW_KUBERNETES_INSTALL: "VIEW_KUBERNETES_INSTALL",
  VIEW_KUBERNETES_UPGRADE: "VIEW_KUBERNETES_UPGRADE",
  VIEW_OTHER_DOCS: "VIEW_OTHER_DOCS",

  CHECKOUT_CLICK_PORTAL: "CHECKOUT_CLICK_PORTAL",

  UPGRADE_CLICK_PORTAL_BANNER: "UPGRADE_CLICK_PORTAL_BANNER",
  UPGRADE_CLICK_PORTAL_LICENSE_PAGE: "UPGRADE_CLICK_PORTAL_LICENSE_PAGE",
  UPDATE_CLICK_PORTAL_PAYMENT_FAILURE: "UPDATE_CLICK_PORTAL_PAYMENT_FAILURE",
  STRIPE_DASHBOARD_OPEN: "STRIPE_DASHBOARD_OPEN",
  TALK_TO_US_CLICK_PORTAL_LICENSE_PAGE_ENTERPRISE:
    "TALK_TO_US_CLICK_PORTAL_LICENSE_PAGE_ENTERPRISE",

  SEAT_COUNT_CHANGE: "SEAT_COUNT_CHANGE",
  INITIATE_SEAT_BASED_CHECKOUT: "INITIATE_SEAT_BASED_CHECKOUT",
  INITIATE_SEAT_BASED_CHECKOUT_FAILED: "INITIATE_SEAT_BASED_CHECKOUT_FAILED",
  UPDATE_SEATS: "UPDATE_SEATS",
  UPDATE_SEATS_FAILED: "UPDATE_SEATS_FAILED",
};

export default analyticEvents;
